var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"info font-weight-bold",on:{"click":_vm.showDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("新規登録")]),_c('span',{staticClass:"d-sm-none"},[_vm._v("新規")])],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"100%","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("お知らせ情報")])]),_c('v-card-text',{staticClass:"scrollable-content"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.id),expression:"item.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.item.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "id", $event.target.value)}}}),_c('ValidationObserver',{ref:"observer"},[_c('h3',{attrs:{"id":"title_head"}},[_vm._v("告知設定")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("店舗に告知する情報を設定して下さい。")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"お知らせ日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"date","error-messages":errors,"success":valid,"label":"お知らせ日","hint":"お知らせする日を入力して下さい[必須項目]","persistent-hint":"","outlined":"","type":"date"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"タイトル","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"title","error-messages":errors,"success":valid,"label":"タイトル","hint":"タイトルを入力してください[必須項目]","persistent-hint":"","outlined":""},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"内容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":"content","error-messages":errors,"success":valid,"label":"内容","hint":"内容を入力してください[必須項目]","persistent-hint":"","outlined":"","rows":"4"},model:{value:(_vm.item.content),callback:function ($$v) {_vm.$set(_vm.item, "content", $$v)},expression:"item.content"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.selectItem.informationTypeItem,"label":"種別","hint":"種別を選択してください","persistent-hint":"","outlined":"","success":_vm.item.type == 0 ? false : true,"rules":[function (v) { return !!v || '種別を選択してください'; }]},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":""},on:{"click-event":_vm.cancelDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("キャンセル")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmit},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }