<template>
  <div>
    <v-app-bar flat color="white">
      <div class="pa-2">
        <information-dialog-component
          ref="refDialogComponent"
          @updated="updated"
        ></information-dialog-component>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import informationDialogComponent from "./component/informationDialogComponent.vue";

export default {
  components: {
    informationDialogComponent,
  },
  props: ["content_format_list"],
  setup(props, ctx) {
    const refDialogComponent = ref(null);

    // app-barにあるコンポーネント呼び出し
    const action = (params) => {
      switch (params.action) {
        case "edit":
          refDialogComponent.value.editDialog(params.value);
          break;
      }
    };

    const updated = (information_data) => {
      ctx.emit("updated", information_data);
    };

    return {
      refDialogComponent,
      action,
      updated,
    };
  },
};
</script>
