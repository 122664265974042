<template>
  <div>
    <v-btn class="info font-weight-bold" @click="showDialog"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">お知らせ情報</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="item.id" />
          <ValidationObserver ref="observer">
            <h3 id="title_head">告知設定</h3>
            <div class="mt-2 mb-2">店舗に告知する情報を設定して下さい。</div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="お知らせ日"
                    rules="required"
                  >
                    <v-text-field
                      v-model="item.date"
                      name="date"
                      :error-messages="errors"
                      :success="valid"
                      label="お知らせ日"
                      hint="お知らせする日を入力して下さい[必須項目]"
                      persistent-hint
                      outlined
                      type="date"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="タイトル"
                    rules="required"
                  >
                    <v-text-field
                      v-model="item.title"
                      name="title"
                      :error-messages="errors"
                      :success="valid"
                      label="タイトル"
                      hint="タイトルを入力してください[必須項目]"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="内容"
                    rules="required"
                  >
                    <v-textarea
                      v-model="item.content"
                      name="content"
                      :error-messages="errors"
                      :success="valid"
                      label="内容"
                      hint="内容を入力してください[必須項目]"
                      persistent-hint
                      outlined
                      rows="4"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="selectItem.informationTypeItem"
                    v-model="item.type"
                    label="種別"
                    hint="種別を選択してください"
                    persistent-hint
                    outlined
                    :success="item.type == 0 ? false : true"
                    :rules="[(v) => !!v || '種別を選択してください']"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { set, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import selectItem from "@/common/selectItem.js";

export default defineComponent({
  setup(props, ctx) {
    const informationsRepository = repositoryFactory.get("informations");
    const state = reactive({
      dialog: false, // ダイアログ表示用
      item: {
        id: 0,
        date: null,
        title: "",
        content: "",
        type: 1,
      },
    });

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const clearDialog = async () => {
      // 各種項目初期化
      state.item.id = 0;
      state.item.date = null;
      state.item.title = "";
      state.item.content = "";
      state.item.type = 1;
    };

    const editDialog = async (userId) => {
      await informationsRepository
        .admin_get(userId)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            state.item.id = response.data.id;
            state.item.date = response.data.date;
            state.item.title = response.data.title;
            state.item.content = response.data.content;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:informationDialogComponent.vue/editDialog informationsRepository.admin_get (" +
            error +
            ")"
          );
        });
      state.dialog = true;
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
        return;
      } else {
        if (!window.confirm("保存してよろしいですか？")) {
          return false;
        }
        // ローディング表示
        store.dispatch("loadingIcon/showIcon");

        // 保存処理
        const params = {
          id: state.item.id,
          date: state.item.date,
          title: state.item.title,
          content: state.item.content,
          type: state.item.type,
          is_active: true,
        };

        // 新規か更新か
        if (state.item.id == 0) {
          // 新規
          await informationsRepository
            .admin_create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.item.id = response.data.id; // idをセット
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:informationDialogComponent.vue/onSubmit informationsRepository.admin_create (" +
                error +
                ")"
              );
            });
        } else {
          // 更新
          await informationsRepository
            .admin_update(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:informationDialogComponent.vue/onSubmit informationsRepository.admin_update (" +
                error +
                ")"
              );
            });
        }

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");

        cancelDialog();

        ctx.emit("updated", params);
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      clearDialog();
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    const init = async () => {
      // 初期化
      clearDialog();
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      selectItem,
      ...toRefs(state),
      editDialog,
      onSubmit,
      cancelDialog,
      cancelCastDialog,
      showDialog,
    };
  },
});
</script>

<style scoped></style>
